import React from "react";
import { Dialog, DialogTitle } from "@headlessui/react";
import { Button } from "@/components/ui/button";

interface SettingsModalProps {
  onClose: () => void;
}

export default function SettingsModal({ onClose }: SettingsModalProps) {
  return (
    <Dialog open onClose={onClose} className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-black opacity-30" />
        <div className="relative bg-white rounded max-w-md mx-auto p-6 z-10">
          <DialogTitle className="text-lg font-bold mb-4">Settings</DialogTitle>
          <div className="space-y-4">
            <Button
              onClick={() => {
                window.location.href = "/api/google/auth";
              }}
              className="w-full flex items-center justify-center"
            >
              Grant Access to Google Calendar
            </Button>
          </div>
          <Button onClick={onClose} className="mt-6 w-full">
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
