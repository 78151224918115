import { z } from "zod";

const envSchema = z.object({
  NEXT_PUBLIC_HASURA_GRAPHQL_URL: z.string().url(),
  NEXT_PUBLIC_VERCEL_ENV: z.string().optional(),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: z.string().optional(),
  NEXT_PUBLIC_VERCEL_URL: z.string().optional(),
  NEXT_PUBLIC_NETIC_ASSISTANT_ID: z.string(),
  NEXT_PUBLIC_GHOST_MODE: z.enum(["true", "false"]).default("false").transform(v => v === "true"),
  NEXT_PUBLIC_GOOGLE_CLOUD_MEDIA_DOMAIN: z.string(),
  NEXT_PUBLIC_SERVICE_TITAN_HOSTNAME: z.string(),
  NEXT_PUBLIC_GOOGLE_CALENDAR_ENABLED: z.enum(["true", "false"]).optional().transform(v => v === "true"),
});

// Must specify full variable names for the NextJS compiler to bundle
const env = envSchema.parse({
  NEXT_PUBLIC_HASURA_GRAPHQL_URL: process.env.NEXT_PUBLIC_HASURA_GRAPHQL_URL,
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
  NEXT_PUBLIC_NETIC_ASSISTANT_ID: process.env.NEXT_PUBLIC_NETIC_ASSISTANT_ID,
  NEXT_PUBLIC_GHOST_MODE: process.env.NEXT_PUBLIC_GHOST_MODE,
  NEXT_PUBLIC_GOOGLE_CLOUD_MEDIA_DOMAIN: process.env.NEXT_PUBLIC_GOOGLE_CLOUD_MEDIA_DOMAIN,
  NEXT_PUBLIC_SERVICE_TITAN_HOSTNAME: process.env.NEXT_PUBLIC_SERVICE_TITAN_HOSTNAME,
  NEXT_PUBLIC_GOOGLE_CALENDAR_ENABLED: process.env.NEXT_PUBLIC_GOOGLE_CALENDAR_ENABLED,
});

export default env;
